@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';
body {
	margin: 0;
	padding: 0;
}
:where(.css-dev-only-do-not-override-d461m6).ant-checkbox-wrapper
	+ .ant-checkbox-wrapper {
	margin-left: 0 !important;
}
